<template>
  <v-container class="cryptomation-container">
    <v-row dense>
      <v-col cols="12">
        <h1>{{ nameMode }}</h1>
      </v-col>

      <v-col cols="12">
        <!-- -->

        <!-- <v-sheet v-if="viewMode === 0 || viewMode === 20" width="300">
          <v-progress-circular indeterminate color="red"></v-progress-circular>
          <p> Verifying .... please wait </p>
        </v-sheet> -->

        <v-sheet
          v-if="viewMode === 20"
          class="pa-5 d-flex flex-column justify-center"
          width="100%"
        >
          <div class="pb-5 text-center">
            <v-progress-circular
              size="100"
              indeterminate
              color="green"
            ></v-progress-circular>
          </div>

          <p class="headline text-center">Verifying .... please wait</p>
        </v-sheet>

        <v-sheet
          v-if="viewMode === 21"
          class="pa-5 d-flex flex-column justify-center"
          width="100%"
        >
          <v-icon color="green" size="100"> mdi-checkbox-marked-circle </v-icon>
          <p class="headline text-center" v-html="headerMessageText">!</p>
          <p class="title text-center" v-html="messageText"></p>
        </v-sheet>

        <v-sheet
          v-if="viewMode === 22"
          class="pa-5 d-flex flex-column justify-center"
          width="100%"
        >
          <v-icon color="red" size="100"> mdi-close-circle-outline </v-icon>
          <p class="headline text-center" v-html="headerMessageText"></p>
          <p class="title text-center" v-html="messageText"></p>
        </v-sheet>

        <v-sheet
          v-if="viewMode === 30"
          class="pa-5 d-flex flex-column justify-center"
          width="100%"
        >
          <form>
            <v-text-field
              v-model="newPassword"
              :error-messages="newPasswordErrors"
              label="New password"
              required
              type="password"
              @input="$v.newPassword.$touch()"
              @blur="$v.newPassword.$touch()"
              color="info"
            ></v-text-field>

            <v-text-field
              v-model="confirmNewPassword"
              :error-messages="confirmNewPasswordErrors"
              label="Confirm new password"
              required
              type="password"
              @input="$v.confirmNewPassword.$touch()"
              @blur="$v.confirmNewPassword.$touch()"
              color="info"
            ></v-text-field>
            <v-btn class="mt-4" outlined @click="changePassword">
              Change password
            </v-btn>
          </form>
        </v-sheet>

        <v-sheet
          v-if="viewMode === 31"
          class="pa-5 d-flex flex-column justify-center"
          width="100%"
        >
          <div class="pb-5 text-center">
            <v-progress-circular
              size="100"
              indeterminate
              color="green"
            ></v-progress-circular>
          </div>

          <p class="headline text-center">Reseting password, Please wait..</p>
        </v-sheet>

        <v-sheet
          v-if="viewMode === 32"
          class="pa-5 d-flex flex-column justify-center"
          width="100%"
        >
          <v-icon color="green" size="100"> mdi-checkbox-marked-circle </v-icon>
          <p class="headline text-center">Reset Password Success !</p>
          <p class="title text-center">
            Your password has been updated. You can now sign in with your new
            password.
          </p>
        </v-sheet>

        <v-sheet
          v-if="viewMode === 33"
          class="pa-5 d-flex flex-column justify-center"
          width="100%"
        >
          <v-icon color="red" size="100"> mdi-close-circle-outline </v-icon>
          <p class="headline text-center">Reset Password Fail !</p>
          <p class="title text-center">
            {{ messageText }}
          </p>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { verifyEmailAPI, resetPasswordAPI } from "@/services/api/authen";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  name: "AuthAction",
  mixins: [validationMixin],
  data: () => ({
    modeQuery: null,
    oobCodeQuery: null,
    viewMode: 0,
    nameMode: "",
    modeState: null,
    headerMessageText: "",
    messageText: "",
    // nameModeList: ["verify", "resetPassword"],
    modeMapping: {
      BAD_REQUEST_MODE: 10,
      //
      VERIFY_EMAIL_MODE: 20,
      VERIFY_EMAIL_MODE_SUCCESS: 21,
      VERIFY_EMAIL_MODE_FAIL: 22,
      VERIFY_EMAIL_MODE_ALREADY: 23,

      RESET_PASSWORD_MODE: 30,
      RESET_PASSWORD_MODE_LOADING: 31,
      RESET_PASSWORD_MODE_SUCCESS: 32,
      RESET_PASSWORD_MODE_FAIL: 33,
    },
    paramMapping: {
      verifyEmail: "VERIFY_EMAIL_MODE",
      resetPassword: "RESET_PASSWORD_MODE",
    },
    //
    newPassword: "",
    confirmNewPassword: "",
  }),
  mounted() {
    this.modeQuery = this.$route.query.mode;
    this.oobCodeQuery = this.$route.query.oobCode;
    // this.modeQuery = "resetPassword"; // resetPassword
    // this.oobCodeQuery = "12345";

    if (!this.checkParam()) {
      this.updateViewMode(this.modeMapping.BAD_REQUEST_MODE);
    } else {
      this.modeState = this.modeMapping[this.paramMapping[this.modeQuery]];
      this.updateViewMode(this.modeState);
      // callAction
      if (this.modeState == this.modeMapping.VERIFY_EMAIL_MODE) {
        this.verifyEmail();
      }
    }
  },
  methods: {
    checkParam() {
      if (this.modeQuery === undefined || this.modeQuery.length === 0) {
        return false;
      } else if (
        this.oobCodeQuery === undefined ||
        this.oobCodeQuery.length === 0
      ) {
        return false;
      } else if (!Object.keys(this.paramMapping).includes(this.modeQuery)) {
        return false;
      }

      return true;
    },
    changeNameMode(currentMode) {
      if (currentMode === this.modeMapping.BAD_REQUEST_MODE) {
        this.nameMode = "Bad Request !";
      } else if (currentMode === this.modeMapping.VERIFY_EMAIL_MODE) {
        this.nameMode = "Verify Email";
      } else if (currentMode === this.modeMapping.RESET_PASSWORD_MODE) {
        this.nameMode = "Reset Password";
      }
    },
    updateViewMode(currentMode) {
      this.changeNameMode(currentMode);
      this.viewMode = currentMode;
    },
    async verifyEmail() {
      let res_data = await verifyEmailAPI(this.oobCodeQuery);
      let _status = -1;
      if (!res_data.error) {
        _status = this.modeMapping.VERIFY_EMAIL_MODE_SUCCESS;
        this.headerMessageText = "Verify Success !";
        this.messageText =
          "Your email has been verified. You can now sign in with your new account.";
      } else {
        this.headerMessageText = "Verify Fail !";
        if (res_data.errorCode == 400) {
          switch (res_data.msg) {
            case "INVALID_OOB_CODE":
              this.messageText = "Invalid verification code!";
              _status = this.modeMapping.VERIFY_EMAIL_MODE_FAIL;
              break;
            case "OOB_CODE_ALREADY_USED":
              this.headerMessageText = "Already verify !";
              this.messageText =
                "This verification code has been used already. <br/>\
              You can check your verification status by going to Menu -> User Management -> Email Verification.";
              _status = this.modeMapping.VERIFY_EMAIL_MODE_SUCCESS;
              break;

            default:
              this.messageText = res_data.msg;
              _status = this.modeMapping.VERIFY_EMAIL_MODE_FAIL;
              break;
          }
        } else {
          this.messageText = res_data.msg;
          _status = this.modeMapping.VERIFY_EMAIL_MODE_FAIL;
        }
      }
      this.updateViewMode(_status);
    },
    async changePassword() {
      if (!this.$v.$invalid) {
        this.updateViewMode(this.modeMapping.RESET_PASSWORD_MODE_LOADING);

        let res_data = await resetPasswordAPI(
          this.oobCodeQuery,
          this.newPassword
        );

        if (!res_data.error) {
          this.updateViewMode(this.modeMapping.RESET_PASSWORD_MODE_SUCCESS);
        } else {
          if (res_data.errorCode == 400) {
            if (res_data.msg == "INVALID_OOB_CODE") {
              this.messageText = "Invalid verification code!";
            } else {
              this.messageText = res_data.msg;
            }
          }
          this.updateViewMode(this.modeMapping.RESET_PASSWORD_MODE_FAIL);
        }
      } else {
        this.$v.$touch();
      }
    },
  },
  computed: {
    newPasswordErrors() {
      const errors = [];
      if (!this.$v.newPassword.$dirty) return errors;
      !this.$v.newPassword.required && errors.push("new password is empty!");
      !this.$v.newPassword.minLength &&
        errors.push("new password must be at most 6 characters long");
      return errors;
    },
    confirmNewPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmNewPassword.$dirty) return errors;
      !this.$v.confirmNewPassword.sameAsPassword &&
        errors.push(
          '"New Password" and "Confirm new password" are not matched!'
        );
      return errors;
    },
  },
  validations: {
    newPassword: { required, minLength: minLength(6) },
    confirmNewPassword: { sameAsPassword: sameAs("newPassword") },
  },
};
</script>
<style>
</style>
